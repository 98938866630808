import React from "react"
import PageRoot from "../components/PageRoot"
import { graphql } from "gatsby"
import EventureGrid from "../components/EventureGrid"
import {
  getCategoryDataSource,
  getImageUrl,
  renderCarouselItem,
} from "../utils"
import ContainerWithPadding from "../components/ContainerWithPadding"
import EventureCarousel from "../components/EventureCarousel"
import ComingSoon from "../components/ComingSoon"
import { useTranslation } from "react-i18next"
import { DEFAULT_LANDSCAPE_IMAGE_SIZE } from "../constants/imageSizes"

const CategoryPage = ({ data }) => {
  const { t } = useTranslation()
  const category = data.eventure.categories_by_pk
  const { name, description, seo, landscape } = category

  const mergedData = getCategoryDataSource(category)
  const hasChildren = category.children?.length > 0 ?? false

  const seoImage = getImageUrl(landscape, DEFAULT_LANDSCAPE_IMAGE_SIZE)

  return (
    <PageRoot
      title={seo.title || name}
      description={seo.description || description}
      image={process.env.GATSBY_AUTH0_REDIRECT_URI + seoImage}
      showTitle
    >
      <div className="video-category-page">
        <ContainerWithPadding size={"large"}>
          {hasChildren && (
            <div className="subcategories-wrapper">
              <EventureCarousel
                title={t("label:subcategories")}
                dataSource={category.children}
                renderItem={renderCarouselItem}
              />
            </div>
          )}
          <EventureGrid
            dataSource={mergedData}
            renderItem={renderCarouselItem}
            emptyItem={<ComingSoon />}
          />
        </ContainerWithPadding>
      </div>
    </PageRoot>
  )
}

export const query = graphql`
  query getCategory($categoryId: eventure_uuid!) {
    eventure {
      categories_by_pk(id: $categoryId) {
        ...CategoryWithAllContent
      }
    }
  }
`

export default CategoryPage
